function LifeBuoyIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<g clipPath="url(#clip0_1906_42258)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.72607 4.78675C2.80235 5.9415 2.25 7.40624 2.25 9C2.25 10.5938 2.80236 12.0585 3.72608 13.2133L5.33195 11.6074C4.8081 10.8718 4.5 9.97188 4.5 9C4.5 8.02813 4.80809 7.12822 5.33193 6.39261L3.72607 4.78675ZM4.78673 3.72608L6.39259 5.33195C7.12821 4.8081 8.02812 4.5 9 4.5C9.97187 4.5 10.8718 4.80809 11.6074 5.33193L13.2133 3.72607C12.0585 2.80235 10.5938 2.25 9 2.25C7.40624 2.25 5.94148 2.80236 4.78673 3.72608ZM14.8304 3.16311C13.3377 1.67207 11.2765 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 11.2765 1.67206 13.3377 3.1631 14.8304C3.16418 14.8315 3.16527 14.8326 3.16637 14.8336C3.16746 14.8347 3.16856 14.8358 3.16966 14.8369C4.66236 16.3279 6.7235 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 6.72347 16.3279 4.66231 14.8369 3.16961C14.8358 3.16852 14.8347 3.16744 14.8336 3.16635C14.8325 3.16527 14.8315 3.16419 14.8304 3.16311ZM14.2739 4.78673L12.6681 6.39259C13.1919 7.1282 13.5 8.02812 13.5 9C13.5 9.97187 13.1919 10.8718 12.6681 11.6074L14.2739 13.2133C15.1976 12.0585 15.75 10.5938 15.75 9C15.75 7.40624 15.1976 5.94148 14.2739 4.78673ZM13.2133 14.2739L11.6074 12.6681C10.8718 13.1919 9.97188 13.5 9 13.5C8.02813 13.5 7.12822 13.1919 6.39261 12.6681L4.78675 14.2739C5.9415 15.1976 7.40624 15.75 9 15.75C10.5938 15.75 12.0585 15.1976 13.2133 14.2739ZM9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6Z"
					fill={color || "#495B84"}
				/>
			</g>
		</svg>
	);
}

export default LifeBuoyIcon;
