function ZapIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0486 0.812066C10.3541 0.944642 10.5355 1.26269 10.4942 1.59307L9.84959 6.75004L14.4958 6.75004C14.6766 6.75001 14.8596 6.74997 15.0092 6.76351C15.1507 6.77632 15.4215 6.8102 15.6565 7.00003C15.9254 7.21735 16.0794 7.54627 16.0742 7.892C16.0697 8.194 15.9223 8.42378 15.8416 8.54064C15.7562 8.66421 15.639 8.80482 15.5233 8.94364L8.82617 16.9802C8.61302 17.236 8.25677 17.3206 7.95136 17.188C7.64595 17.0554 7.4645 16.7374 7.5058 16.407L8.15042 11.25L3.50417 11.25C3.32343 11.2501 3.14036 11.2501 2.99079 11.2366C2.84933 11.2238 2.57846 11.1899 2.34355 11.0001C2.07462 10.7827 1.92056 10.4538 1.92577 10.1081C1.93033 9.80609 2.07771 9.57631 2.15843 9.45944C2.24378 9.33586 2.36101 9.19524 2.47675 9.0564C2.48248 9.04953 2.48821 9.04266 2.49393 9.03579L9.17384 1.0199C9.38698 0.764128 9.74324 0.679489 10.0486 0.812066ZM3.85128 9.75004H9C9.21513 9.75004 9.41989 9.84242 9.56226 10.0037C9.70463 10.165 9.77089 10.3796 9.74421 10.5931L9.31151 14.0547L14.1487 8.25004H9C8.78488 8.25004 8.58011 8.15767 8.43775 7.99639C8.29538 7.83512 8.22911 7.62048 8.25579 7.40702L8.6885 3.94538L3.85128 9.75004Z"
				fill={color || "#495B84"}
			/>
		</svg>
	);
}

export default ZapIcon;
