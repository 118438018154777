function SearchIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C9.66446 13.5 10.9483 12.9406 11.8923 12.031C11.912 12.0054 11.9337 11.9807 11.9572 11.9572C11.9807 11.9337 12.0054 11.912 12.031 11.8923C12.9406 10.9483 13.5 9.66446 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM13.5239 12.4633C14.4476 11.3085 15 9.84376 15 8.25C15 4.52208 11.9779 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.9779 4.52208 15 8.25 15C9.84376 15 11.3085 14.4476 12.4633 13.5239L15.2197 16.2803C15.5126 16.5732 15.9874 16.5732 16.2803 16.2803C16.5732 15.9874 16.5732 15.5126 16.2803 15.2197L13.5239 12.4633ZM7.5 4.5C7.5 4.08579 7.83579 3.75 8.25 3.75C10.7353 3.75 12.75 5.76472 12.75 8.25C12.75 8.66421 12.4142 9 12 9C11.5858 9 11.25 8.66421 11.25 8.25C11.25 6.59315 9.90685 5.25 8.25 5.25C7.83579 5.25 7.5 4.91421 7.5 4.5Z"
				fill={color || "#495B84"}
			/>
		</svg>
	);
}

export default SearchIcon;
